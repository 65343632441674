import { Link } from "gatsby"
import React from "react"
import Col from "react-bootstrap/Col"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Frame from "../../images/mobile-app-developmemt/Frame.svg"
import dots from "../../images/mobile-app-developmemt/Group.svg"
import sneakrec from "../../images/mobile-app-developmemt/sneakrec.svg"
import sneakrea2 from "../../images/mobile-app-developmemt/sneakrea2.svg"
import arrow from "../../images/mobile-app-developmemt/arrow.svg"
import * as styles from "./DevelopmentServices.module.scss"

const Achieve = ({ strapiData }) => {
  return (
    <>
      <div className={`${styles.subservice}`}>
        <div className={styles.sneakrec}>
          <img src={sneakrec} alt="rectangle" decoding="async" loading="lazy" />
        </div>
        <div className={styles.dots1}>
          <img decoding="async" alt="dots" loading="lazy" src={dots} />
        </div>
        <Container>
          <h2
            className={styles.heading}
            dangerouslySetInnerHTML={{ __html: strapiData?.title }}
          />
          <div
            className={styles.description}
            dangerouslySetInnerHTML={{
              __html: strapiData?.subTitle,
            }}
          />
          <div className="gap-30">
            <Row className={` pt-5 ${styles.cardWrapper}`}>
              {strapiData?.cards &&
                strapiData?.cards?.map(
                  (e, i) =>
                    i < 3 && (
                      <Col lg={4} xs={12} key={i}>
                        <div className={styles.card}>
                          {e?.buttons[0]?.url && (
                            <Link to={e?.buttons[0]?.url}>
                              <img
                                className={styles.arrowImg}
                                src={arrow}
                                decoding="async"
                                loading="lazy"
                                alt="arrow"
                              />
                            </Link>
                          )}
                          <img
                            alt={e?.image1[0]?.alternativeText}
                            src={e?.image1[0]?.localFile?.publicURL}
                            decoding="async"
                            loading="lazy"
                          />
                          <h3>{e?.title}</h3>
                          <p
                            dangerouslySetInnerHTML={{
                              __html: e?.subTitle,
                            }}
                          />
                        </div>
                      </Col>
                    )
                )}
            </Row>
            <Row className={`gap-30 pt-4 ${styles.cardWrapper}`}>
              {strapiData?.cards &&
                strapiData?.cards?.map(
                  (e, i) =>
                    i > 2 && (
                      <Col lg={6} xs={12} key={i} className="d-flex">
                        <div className={styles.card2}>
                          {e?.buttons[0]?.url && (
                            <Link to={e?.buttons[0]?.url}>
                              <img
                                className={styles.arrowImg}
                                src={arrow}
                                decoding="async"
                                loading="lazy"
                                alt="arrow"
                              />
                            </Link>
                          )}
                          <img
                            src={e?.image1[0]?.localFile?.publicURL}
                            decoding="async"
                            loading="lazy"
                            alt={e?.image1[0]?.alternativeText}
                          />
                          <h3>{e?.title}</h3>
                          <p
                            dangerouslySetInnerHTML={{
                              __html: e?.subTitle,
                            }}
                          />
                        </div>
                      </Col>
                    )
                )}
            </Row>
            <div className={styles.dots2}>
              <img decoding="async" alt="dots" loading="lazy" src={dots} />
            </div>
          </div>
          <div className={styles.teamBtn}>
            {strapiData?.buttons[0] && (
              <Link
                to={strapiData?.buttons[0]?.url}
                className="btn_black_border"
              >
                {strapiData?.buttons[0]?.title}
              </Link>
            )}
          </div>
        </Container>
        <div className={styles.dots3}>
          <img decoding="async" alt="Frame" loading="lazy" src={Frame} />
        </div>
        <div className={styles.sneakrec2}>
          <img
            src={sneakrea2}
            alt="rectangle"
            decoding="async"
            loading="lazy"
          />
        </div>
      </div>
    </>
  )
}

export default Achieve
