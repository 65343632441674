// extracted by mini-css-extract-plugin
export var arrowImg = "DevelopmentServices-module--arrowImg--37219";
export var card = "DevelopmentServices-module--card--47e56";
export var card2 = "DevelopmentServices-module--card2--39311";
export var cardFlex = "DevelopmentServices-module--cardFlex--bb48a";
export var cardWrapper = "DevelopmentServices-module--cardWrapper--228e4";
export var description = "DevelopmentServices-module--description--2b457";
export var dots1 = "DevelopmentServices-module--dots1--e5d75";
export var dots2 = "DevelopmentServices-module--dots2--59265";
export var dots3 = "DevelopmentServices-module--dots3--02edc";
export var heading = "DevelopmentServices-module--heading--d510f";
export var sneakrec = "DevelopmentServices-module--sneakrec--f49c3";
export var sneakrec2 = "DevelopmentServices-module--sneakrec2--4c3e9";
export var subservice = "DevelopmentServices-module--subservice--b2372";
export var teamBtn = "DevelopmentServices-module--teamBtn--88580";