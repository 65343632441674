import { Link } from "gatsby"
import React from "react"
import Container from "react-bootstrap/Container"
import * as styles from "./EngagementModels.module.scss"

const EngagementModels = ({ strapiData, cards = false, software, sqa }) => {
  const data = [
    {
      title: "Staff Augmentation",
      icon: "https://invozone-backend.s3.amazonaws.com/staff_augmentation_2d38971296.svg",
    },
    {
      title: "Dedicated Teams",
      icon: "https://invozone-backend.s3.amazonaws.com/dedicated_teams_b039269a37.svg",
    },
    {
      title: "Product Development",
      icon: "https://invozone-backend.s3.amazonaws.com/product_Development_253e7272da.svg",
    },
  ]

  return (
    <div className={`${styles.customized} ${sqa ? styles.sqaCustomized : ""}`}>
      <Container className={styles.con}>
        <div className={styles.engagment}>
          <h2
            className={`${styles.mainHeading} ${
              software ? styles.mainHeadingSoftware : ""
            }`}
          >
            {strapiData.title ||
              "Unlock Business Excellence With Our Versatile Engagement Models"}
          </h2>
          <p
            className={`${styles.subHeading} ${
              software ? styles.mainSubHeadingSoftware : ""
            } ${sqa ? styles.mainsubHeadingSQA : ""} pt-2`}
          >
            {strapiData.subTitle ||
              "Transform your vision into reality and drive exemplary results through InvoZone's dynamic engagement models."}
          </p>
          <div className={styles.flexWrapper}>
            {!cards
              ? data?.map(item => (
                  <div className={`${styles.card}`} key={item?.title}>
                    <div>
                      <img
                        decoding="async"
                        loading="lazy"
                        src={item?.icon}
                        alt={item?.title}
                      />
                    </div>
                    <h3 className={`${styles.pHead} `}>{item?.title}</h3>
                  </div>
                ))
              : strapiData?.cards &&
                strapiData?.cards?.map(item => (
                  <div className={`${styles.card}`} key={item?.title}>
                    <div>
                      <img
                        decoding="async"
                        loading="lazy"
                        src={item?.image1[0]?.localFile?.publicURL}
                        alt={item?.image1[0]?.alternativeText}
                      />
                    </div>
                    <h3 className={`${styles.pHead} `}>{item?.title}</h3>
                    {/* <div className={`${styles.sHead}`}>
                      {item?.secondaryTitle}
                    </div> */}
                    {/* <div className={`${styles.Head}`}>{item?.subTitle}</div> */}
                  </div>
                ))}
          </div>
          <div className={styles.teamBtn2}>
            <Link to={`/pricing/`} className={styles.btn}>
              {strapiData?.buttons[0]?.title || "Get The Best Quote Today"}
            </Link>
          </div>
        </div>
        <div className={styles.teamBtn}>
          <Link to={`/pricing/`} className={styles.btn}>
            {strapiData?.buttons[0]?.title || "Get The Best Quote Today"}
          </Link>
        </div>
      </Container>
    </div>
  )
}

export default EngagementModels
