import React, { Suspense, lazy, useEffect, useState } from "react"
import Container from "react-bootstrap/Container"
import "./ContactSales.scss"
import * as styles from "./ContactSalesBg.module.scss"

const ContactSales = () => {
  const [state, setState] = useState(false)
  const Form = state
    ? lazy(() => import("../../components/form-footer/Form"))
    : null

  useEffect(() => {
    if (typeof window !== "undefined") {
      const handleScroll = () => {
        setState(true)
      }
      window.addEventListener("scroll", handleScroll)
      return () => {
        window.removeEventListener("scroll", handleScroll)
      }
    }
  }, [])

  return (
    <div className={styles.contactSales}>
      <Container>
        <Suspense fallback={<div>Loading...</div>}>{Form && <Form />}</Suspense>
      </Container>
    </div>
  )
}

export default ContactSales
