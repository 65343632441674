// extracted by mini-css-extract-plugin
export var boxContent = "DevelopmentProcess-module--boxContent--829a6";
export var bulitLine = "DevelopmentProcess-module--bulitLine--50f19";
export var cards = "DevelopmentProcess-module--cards--b23f6";
export var cardsContent = "DevelopmentProcess-module--cardsContent--1fd33";
export var cardsZ = "DevelopmentProcess-module--cardsZ--538ea";
export var description = "DevelopmentProcess-module--description--778ef";
export var devepDots1 = "DevelopmentProcess-module--devepDots1--d8dc5";
export var devepSer1 = "DevelopmentProcess-module--devepSer1--d6631";
export var dots1 = "DevelopmentProcess-module--dots1--c2e27";
export var dots2 = "DevelopmentProcess-module--dots2--92879";
export var fineBg = "DevelopmentProcess-module--fineBg--a5319";
export var heading = "DevelopmentProcess-module--heading--8cbcc";
export var tabbingCards = "DevelopmentProcess-module--tabbingCards--f8c6d";