import { Link } from "gatsby"
import React from "react"
import Col from "react-bootstrap/Col"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import faqsBg from "../../images/hire-python-developr/faqsBg.png"
import * as styles from "./TrailPop.module.scss"
import "./Trailpop.scss"
const TrailPop = ({ strapiData }) => {
  return (
    <div className={styles.needResources}>
      <div className={styles.needBg3}></div>
      <Container>
        <Row className={styles.centeredRow}>
          <Col lg={7}>
            <div className={styles.heading}>
              <h2 dangerouslySetInnerHTML={{ __html: strapiData?.title }} />
            </div>
            {strapiData?.buttons[0] && (
              <Link
                to={strapiData?.buttons[0]?.url}
                className="btn_blue1_border"
              >
                {strapiData?.buttons[0]?.title}
              </Link>
            )}
          </Col>
          <Col lg={5}>
            <div className={styles.trailImg}>
              <img
                decoding="async"
                loading="lazy"
                src={strapiData?.secImages[0].localFile.publicURL}
                alt={strapiData?.secImages[0]?.alternativeText}
              />
            </div>
          </Col>
        </Row>
      </Container>
      <div className={styles.faqsBg}>
        <img decoding="async" alt="faq bg" loading="lazy" src={faqsBg} />
      </div>
    </div>
  )
}

export default TrailPop
