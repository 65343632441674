import { Link } from "gatsby"
import React from "react"
import Col from "react-bootstrap/Col"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import hire2 from "../../images/mobile-app-developmemt/hire1.png"
import hire from "../../images/mobile-app-developmemt/hire2.png"
import * as styles from "./HireMobile.module.scss"

const HireMobile = ({ strapiData }) => {
  return (
    <>
      <div className={styles.hire2}>
        <img decoding="async" alt="hire" loading="lazy" src={hire2} />
      </div>
      <div className={`${styles.subservice}`}>
        <Container>
          <h2
            className={styles.heading}
            dangerouslySetInnerHTML={{ __html: strapiData?.title }}
          />
          <div
            className={styles.description}
            dangerouslySetInnerHTML={{
              __html: strapiData?.subTitle,
            }}
          />
          <Row className={`gap-30 ${styles.cardWrapper}`}>
            {strapiData?.cards &&
              strapiData?.cards?.map((e, i) => (
                <Col md={6} xs={12} key={i}>
                  <div className={styles.card}>
                    <div className={styles.fineBg}>
                      <img
                        src={e?.image1[0]?.localFile?.publicURL}
                        alt={e?.image1[0]?.alternativeText}
                        decoding="async"
                        loading="lazy"
                      />
                    </div>
                    <h3>{e?.title}</h3>
                    <p
                      dangerouslySetInnerHTML={{
                        __html: e?.subTitle,
                      }}
                    />
                  </div>
                </Col>
              ))}
          </Row>
          <div className={styles.teamBtn}>
            {strapiData?.buttons[0] && (
              <Link
                to={strapiData?.buttons[0]?.url}
                className="btn_black_border"
              >
                {strapiData?.buttons[0]?.title}
              </Link>
            )}
          </div>
        </Container>
        <div className={styles.hire1}>
          <img decoding="async" alt="hire" loading="lazy" src={hire} />
        </div>
      </div>
    </>
  )
}

export default HireMobile
