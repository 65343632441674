import React from "react"
import Col from "react-bootstrap/Col"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import teamBg from "../../images/mobile-app-developmemt/teamwork.png"
import Dots from "../../images/product-development/dotsquare.svg"
import AccordianHover from "./AccordianHover"
import * as styles from "./TeamWork.module.scss"
import "../application-development/TeamWork.scss"

const TeamWork = ({ strapiData }) => {
  return (
    <div className={styles.wellGroundedMain}>
      <div className={styles.teamworkBg}>
        <img decoding="async" alt="teamBg" loading="lazy" src={teamBg} />
      </div>
      <Container>
        <h2 dangerouslySetInnerHTML={{ __html: strapiData?.title }} />

        <div
          className={`${styles.subHeading}`}
          dangerouslySetInnerHTML={{
            __html: strapiData?.subTitle,
          }}
        />
        <div>
          <Row className="accor ">
            <Col lg={6} md={12} className="text-lg-left">
              <div className="cardData">
                {strapiData?.cards &&
                  strapiData?.cards?.map((el, id) => (
                    <AccordianHover
                      key={id}
                      image={el?.image1[0]?.localFile?.publicURL}
                      Heading={el?.title}
                      AccordianBody={
                        <div
                          dangerouslySetInnerHTML={{
                            __html: el?.subTitle,
                          }}
                        ></div>
                      }
                    />
                  ))}
              </div>
            </Col>

            <Col
              lg={6}
              md={12}
              className={`${styles.teamImg} text-center text-lg-right mt-5 mt-md-0`}
            >
              <img
                src={strapiData?.secImages[0].localFile.publicURL}
                alt={strapiData?.secImages[0]?.alternativeText}
                width="100%"
                decoding="async"
                loading="lazy"
                height="auto"
              />
              <img
                className={styles.bannerTopIcon}
                src={Dots}
                alt="triangle"
                decoding="async"
                loading="lazy"
              />
            </Col>
          </Row>
        </div>
      </Container>
    </div>
  )
}

export default TeamWork
