// extracted by mini-css-extract-plugin
export var button = "TrailPop-module--button--ec648";
export var centeredRow = "TrailPop-module--centeredRow--e6153";
export var faqsBg = "TrailPop-module--faqsBg--88fea";
export var heading = "TrailPop-module--heading--e41e2";
export var needBg = "TrailPop-module--needBg--9f7b9";
export var needBg3 = "TrailPop-module--needBg3--64ef2";
export var needCircle = "TrailPop-module--needCircle--e19c1";
export var needCircle2 = "TrailPop-module--needCircle2--6e810";
export var needResources = "TrailPop-module--needResources--ed7f7";
export var trailImg = "TrailPop-module--trailImg--f000a";