// extracted by mini-css-extract-plugin
export var Head = "EngagementModels-module--Head--065f8";
export var btn = "EngagementModels-module--btn--f07fb";
export var card = "EngagementModels-module--card--49dea";
export var con = "EngagementModels-module--con--c6774";
export var customized = "EngagementModels-module--customized--98ee7";
export var engagment = "EngagementModels-module--engagment--08989";
export var flexWrapper = "EngagementModels-module--flexWrapper--fd313";
export var iosegage = "EngagementModels-module--iosegage--eb9c5";
export var mainHeading = "EngagementModels-module--mainHeading--6143f";
export var mainHeadingSoftware = "EngagementModels-module--mainHeadingSoftware--26602";
export var mainSubHeadingSoftware = "EngagementModels-module--mainSubHeadingSoftware--7d139";
export var mainsubHeadingSQA = "EngagementModels-module--mainsubHeadingSQA--7162f";
export var pHead = "EngagementModels-module--pHead--b6403";
export var sHead = "EngagementModels-module--sHead--9d52a";
export var sqaCustomized = "EngagementModels-module--sqaCustomized--5fccf";
export var subHeading = "EngagementModels-module--subHeading--ed660";
export var teamBtn = "EngagementModels-module--teamBtn--3dc66";
export var teamBtn2 = "EngagementModels-module--teamBtn2--41cc5";