// extracted by mini-css-extract-plugin
export var Frame = "AdvancedTech-module--Frame--84c19";
export var Rec = "AdvancedTech-module--Rec--88366";
export var advance = "AdvancedTech-module--advance--d0564";
export var cir = "AdvancedTech-module--cir--5713d";
export var dots = "AdvancedTech-module--dots--31af7";
export var for1 = "AdvancedTech-module--for1--612c7";
export var heading = "AdvancedTech-module--heading--4e88d";
export var iconssCard = "AdvancedTech-module--iconssCard--27c70";
export var techBg = "AdvancedTech-module--techBg--c32d8";
export var techDot = "AdvancedTech-module--techDot--eb6f9";
export var techIcon = "AdvancedTech-module--techIcon--638a0";
export var techImg = "AdvancedTech-module--techImg--3c0bf";
export var technologyIcon = "AdvancedTech-module--technologyIcon--89b47";