// extracted by mini-css-extract-plugin
export var SliderWidth = "Global-module--SliderWidth--89370";
export var card = "Global-module--card--0cddf";
export var cardWrapper = "Global-module--cardWrapper--32afa";
export var description = "Global-module--description--394de";
export var fineBg = "Global-module--fineBg--08ba4";
export var heading = "Global-module--heading--0de69";
export var headingPremium = "Global-module--headingPremium--f8e40";
export var iconContainer = "Global-module--iconContainer--28449";
export var iconContainerLeft = "Global-module--iconContainerLeft--4ee74";
export var portfolioArrowIcon = "Global-module--portfolioArrowIcon--37422";
export var portfolioArrowIconCover = "Global-module--portfolioArrowIconCover--0c978";
export var portfolioArrowRightIconCover = "Global-module--portfolioArrowRightIconCover--2d45e";
export var premiumBg = "Global-module--premiumBg--52d5c";
export var premiumImg = "Global-module--premiumImg--f72d5";
export var teamButton = "Global-module--teamButton--ba83d";
export var teamWork = "Global-module--teamWork--ee91b";