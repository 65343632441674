import { Link } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import React, { useEffect, useRef, useState } from "react"
import Col from "react-bootstrap/Col"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Slider from "react-slick"
import "slick-carousel/slick/slick-theme.scss"
import "slick-carousel/slick/slick.scss"
import LeftArrow from "../../images/mobile-app-developmemt/Group35057.png"
import RightArrow from "../../images/mobile-app-developmemt/rightarrow1.png"
import * as styles from "./Global.module.scss"
import "../custom-web-application/SnapshotMobile.scss"

function Arrow(props) {
  const { onClick } = props
  return (
    <div className={styles.iconContainer}>
      <div className={styles.portfolioArrowRightIconCover} onClick={onClick}>
        <img
          decoding="async"
          loading="lazy"
          src={RightArrow}
          alt="arrow"
          onClick={onClick}
          className={styles.portfolioArrowIcon}
        />
      </div>
    </div>
  )
}
function PrevArrow(props) {
  const { onClick } = props
  return (
    <div className={styles.iconContainerLeft}>
      <div className={styles.portfolioArrowIconCover} onClick={onClick}>
        <img
          decoding="async"
          loading="lazy"
          alt="arrow"
          src={LeftArrow}
          className={styles.portfolioArrowIcon}
        />
      </div>
    </div>
  )
}

const Global = ({ strapiData }) => {
  const image = getImage(strapiData[0]?.secImages[0]?.localFile)

  const [current, setCurrent] = useState(0)
  const slide1 = useRef(null)

  useEffect(() => {
    current === strapiData[1]?.cards.length - 1 &&
      setTimeout(() => {
        slide1?.current?.slickGoTo(0)
      }, 5000)
  }, [current])

  const settings = {
    autoplay: true,
    slidesToShow: 2,
    slidesToScroll: 1,
    speed: 200,
    infinite: false,
    arrows: true,
    prevArrow: <PrevArrow />,
    beforeChange: (current, next) => setCurrent(next),
    nextArrow: <Arrow />,
    lazyLoad: "ondemand",
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  }

  return (
    <React.Fragment>
      <div className={styles.premiumBg}>
        <Container>
          <Row>
            <Col lg={7}>
              <div className={styles.headingPremium}>
                <div className="headingYellow">
                  <h2
                    dangerouslySetInnerHTML={{ __html: strapiData[0]?.title }}
                  />
                </div>
              </div>
              <div className={styles.teamButton}>
                <Link
                  to={strapiData[0]?.buttons[0]?.url}
                  variant="light "
                  className="btn_white_border"
                >
                  {strapiData[0]?.buttons[0]?.title}
                </Link>
              </div>
            </Col>
            <Col lg={5}>
              <div className={styles.premiumImg}>
                {image ? (
                  <GatsbyImage
                    placeholder="blurred"
                    decoding="async"
                    loading="lazy"
                    image={image}
                    alt={strapiData[0]?.title}
                  />
                ) : (
                  <img
                    src={strapiData[0]?.secImages[0]?.url}
                    alt={strapiData[0]?.title}
                    decoding="async"
                    loading="lazy"
                  />
                )}
              </div>
            </Col>
          </Row>
        </Container>
        <div className={styles.teamWork}>
          <Container>
            <h2
              className={styles.heading}
              dangerouslySetInnerHTML={{ __html: strapiData[1]?.title }}
            />
            <div
              className={styles.description}
              dangerouslySetInnerHTML={{
                __html: strapiData[1]?.subTitle,
              }}
            />
            <Row className={`gap-30 ${styles.cardWrapper}`}>
              <Slider {...settings} className={styles.SliderWidth} ref={slide1}>
                {strapiData[1]?.cards &&
                  strapiData[1]?.cards?.map((e, i) => (
                    <Col xs={12} key={i}>
                      <div className={styles.card}>
                        <div className={styles.fineBg}>
                          <img
                            src={e?.image1[0]?.localFile?.publicURL}
                            decoding="async"
                            loading="lazy"
                            alt={e?.image1[0]?.alternativeText}
                          />
                        </div>
                        <h3>{e?.title}</h3>
                        <p
                          className="mb-3"
                          dangerouslySetInnerHTML={{
                            __html: e?.subTitle,
                          }}
                        />
                        {e?.buttons[0]?.title && (
                          <div>
                            <Link
                              to={e?.buttons[0]?.url}
                              variant="light "
                              className="btn_blue_border "
                            >
                              {e?.buttons[0]?.title}
                            </Link>
                          </div>
                        )}
                      </div>
                    </Col>
                  ))}
              </Slider>
            </Row>
          </Container>
        </div>
      </div>
    </React.Fragment>
  )
}

export default Global
