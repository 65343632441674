import React, { useState } from "react"
import Col from "react-bootstrap/Col"
import ArrowDown from "../../images/mobile-app-developmemt/downarrow.svg"
import ArrowUp from "../../images/mobile-app-developmemt/Upwardarrow.svg"
import * as styles from "./AccordianHover.module.scss"

const AccordianHover = ({ image, Heading, AccordianBody }) => {
  const [hover, setHover] = useState(false)
  const [mobile, isMobile] = useState(false)

  const mobileQuery = () => {
    if (window.innerWidth < 768) {
      isMobile(true)
    } else {
      isMobile(false)
    }
  }

  React.useEffect(() => {
    isMobile(window.innerWidth < 768)
    window.addEventListener("resize", mobileQuery)

    return () => {
      window.removeEventListener("resize", mobileQuery)
    }
  }, [])

  return (
    <Col lg={12} md={12}>
      <div
        className={`${styles.accordianMain} mb-3`}
        style={{ height: hover ? (mobile ? "350px" : "255px") : "" }}
        onMouseEnter={() => !mobile && setHover(true)}
        onMouseLeave={() => setHover(false)}
        onClick={() => (mobile ? setHover(!hover) : null)}
      >
        <div
          className={styles.accordianHead}
          // onMouseEnter={() => !mobile && setHover(true)}
          // onMouseLeave={() => setHover(false)}
          // onClick={() => setHover(!hover)}
        >
          <div className={styles.iconHeading}>
            <img decoding="async" loading="lazy" src={image} alt="icon" />
            <h3 className={` pl-4`}>{Heading}</h3>
          </div>
          <div>
            <img
              decoding="async"
              loading="lazy"
              width="100%"
              height="auto"
              src={hover ? ArrowUp : ArrowDown}
              alt="arrow-icon"
            />
          </div>
        </div>
        <div className={styles.accordianBody}>{AccordianBody}</div>
      </div>
    </Col>
  )
}

export default AccordianHover
