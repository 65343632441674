import React from "react"
import Col from "react-bootstrap/Col"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import group from "../../images/mobile-app-developmemt/Group.png"
import * as styles from "./ChoseInvozone.module.scss"

const ChoseInvozone = ({ strapiData }) => {
  return (
    <>
      <div className={`${styles.subservice}`}>
        <Container>
          <h2
            className={styles.heading}
            dangerouslySetInnerHTML={{ __html: strapiData?.title }}
          />
          <div
            className={styles.description}
            dangerouslySetInnerHTML={{
              __html: strapiData?.subTitle,
            }}
          />
          <Row className={`gap-30 ${styles.cardWrapper}`}>
            {strapiData?.cards.map((e, i) => (
              <Col md={6} xs={12} key={i}>
                <div className={styles.card}>
                  <span>0{i + 1}</span>
                  <h3>{e?.title}</h3>
                  <p>{e?.subTitle}</p>
                </div>
              </Col>
            ))}
          </Row>
        </Container>
        <div className={styles.choseImg}>
          <img decoding="async" alt="background" loading="lazy" src={group} />
        </div>
      </div>
    </>
  )
}

export default ChoseInvozone
