import React, { useState } from "react"
import Container from "react-bootstrap/Container"
import Nav from "react-bootstrap/Nav"
import Tab from "react-bootstrap/Tab"
import devepSer from "../../images/mobile-app-developmemt/devepSer.svg"
import dots from "../../images/mobile-app-developmemt/Group.svg"
import line from "../../images/mobile-app-developmemt/line.svg"
import * as styles from "./DevelopmentProcess.module.scss"
import "./MobileApp.scss"

const DevelopmentProcess = ({ strapiData }) => {
  const [activeTab, setActiveTab] = useState(strapiData?.cards[0]?.title)

  const handleTabClick = label => {
    setActiveTab(label)
  }

  return (
    <>
      <div className="p-85 cardTabing">
        <div className={styles.dots1}>
          <img decoding="async" alt="dots" loading="lazy" src={dots} />
        </div>
        <div className={styles.dots2}>
          <img decoding="async" alt="dots" loading="lazy" src={dots} />
        </div>
        <Container>
          <h2
            className={styles.heading}
            dangerouslySetInnerHTML={{ __html: strapiData?.title }}
          ></h2>
          <div
            className={styles.description}
            dangerouslySetInnerHTML={{
              __html: strapiData?.subTitle,
            }}
          />
          <div className="Blocks">
            {" "}
            {/* <Tab.Content className="contentBlocks"></Tab.Content> */}
          </div>
          <div className="Blocks2">
            {" "}
            <Tab.Content className="contentBlocks2"></Tab.Content>
          </div>
          <Tab.Container id="left-tabs-example" defaultActiveKey="Planning">
            <div className={styles.cards}>
              <div className={styles.cardsContent}>
                <div>
                  <div className={styles.bulitLine}>
                    <img
                      decoding="async"
                      alt="line"
                      loading="lazy"
                      src={line}
                    />
                  </div>
                  <Nav variant="pills" className={`flex-column navbarBlock`}>
                    {strapiData?.cards?.map((e, i) => (
                      <Nav.Item key={i}>
                        <div
                          // eventKey={`${e?.title}`}
                          className={`tabLink ${
                            activeTab === e.title ? "active" : ""
                          }`}
                          onClick={() => handleTabClick(e.title)}
                        >
                          {e?.title}
                        </div>
                      </Nav.Item>
                    ))}
                  </Nav>
                </div>
                <Tab.Content className="contentBlock">
                  {strapiData?.cards?.map((e, i) => (
                    <div
                      eventKey={`${e?.title}`}
                      key={i}
                      className={`tab-pane ${
                        activeTab === e.title ? "active" : ""
                      }`}
                    >
                      <div className={styles.boxContent}>
                        <div>
                          <div className={styles.fineBg}>
                            <img
                              decoding="async"
                              loading="lazy"
                              src={e?.image1[0]?.localFile?.publicURL}
                              alt={e?.image1[0]?.alternativeText}
                            />
                          </div>
                          <h3>{e?.title}</h3>
                          <p
                            dangerouslySetInnerHTML={{
                              __html: e?.subTitle,
                            }}
                          ></p>
                        </div>
                      </div>
                    </div>
                  ))}
                </Tab.Content>
              </div>
            </div>
          </Tab.Container>
        </Container>

        <div className={styles.devepSer1}>
          <img
            decoding="async"
            alt="background"
            loading="lazy"
            src={devepSer}
          />
        </div>
      </div>
    </>
  )
}
export default DevelopmentProcess
