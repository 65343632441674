import { graphql } from "gatsby"
import React from "react"
import ContactSales from "../components/common/ContactSales"
import EngagementModels from "../components/common/Engagement-Model/EngagementModels"
import Faqs from "../components/common/Faqs2"
import SEORevamp from "../components/common/SEO_Revamp"
import WorkPortfolio from "../components/common/WorkPortfolio"
import AdvancedTech from "../components/mobile-app-development/AdvancedTech"
import Banner from "../components/mobile-app-development/Banner"
import ChoseInvozone from "../components/mobile-app-development/ChoseInvozone"
import DevelopmentProcess from "../components/mobile-app-development/DevelopmentProcess"
import DevelopmentServices from "../components/mobile-app-development/DevelopmentServices"
import Global from "../components/mobile-app-development/Global"
import HireMobile from "../components/mobile-app-development/HireMobile"
import TeamWork from "../components/mobile-app-development/TeamWork"
import TrailPop from "../components/mobile-app-development/TrailPop"
import MainLayout from "../layouts/MainLayout"
export const Head = ({ data }) => {
  const { metaTitle, metaDescription } = data?.strapiPage?.seo
  let headSchema = []
  const bodySchema = data?.strapiPage?.seo?.schemas?.filter(v => {
    return v?.visibilityIn === true ? v : headSchema?.push(v) && false
  })
  return (
    <SEORevamp
      title={metaTitle}
      description={metaDescription}
      schemas={headSchema}
      image="https://invozone-backend.s3.amazonaws.com/mobile_app_development_ea71dda0e9.svg"
    />
  )
}
const mobileApp = ({ data }) => {
  // console.log(data)
  const heroSection = data?.strapiPage?.sections[0]
  const developmentService = data?.strapiPage?.sections[1]
  const choseInvozone = data?.strapiPage?.sections[2]
  const globalIndustires = [
    data?.strapiPage?.sections[3],
    data?.strapiPage?.sections[4],
  ]
  const developmentProcess = data?.strapiPage?.sections[5]
  const trailPop = data?.strapiPage?.sections[6]
  const teamWork = data?.strapiPage?.sections[7]
  const hireMobile = data?.strapiPage?.sections[8]
  const exploreInvozone = data?.strapiPage?.sections[9]
  const workPortfolio = data?.strapiPage?.sections[10]
  const advancedTech = data?.strapiPage?.sections[11]
  const faqs = data?.strapiPage?.sections[12]

  let headSchema = []
  const bodySchema = data?.strapiPage?.seo?.schemas?.filter(v => {
    return v?.visibilityIn === true ? v : headSchema?.push(v) && false
  })

  return (
    <MainLayout bgChanged={false} schemas={bodySchema}>
      <Banner strapiData={heroSection} breadCrumb={data?.strapiPage?.buttons} />
      <DevelopmentServices strapiData={developmentService} />
      <ChoseInvozone strapiData={choseInvozone} />
      <Global strapiData={globalIndustires} />
      <DevelopmentProcess strapiData={developmentProcess} />
      <TrailPop strapiData={trailPop} />
      <TeamWork strapiData={teamWork} />
      <HireMobile strapiData={hireMobile} />
      <EngagementModels strapiData={exploreInvozone} />
      <WorkPortfolio strapiData={workPortfolio} />
      <AdvancedTech strapiData={advancedTech} />
      <Faqs strapiData={faqs} />
      <ContactSales bgColor="#f8f8f8" />
    </MainLayout>
  )
}

export const query = graphql`
  query mobileAppDevelopments {
    strapiPage(slug: { eq: "mobile-app-developments" }) {
      sections {
        title
        subTitle
        cards {
          title
          subTitle
          description: childStrapiComponentCardsCardDescriptionTextnode {
            description
          }
          image1 {
            alternativeText
            localFile {
              publicURL
            }
          }
          image2 {
            alternativeText
            localFile {
              publicURL
            }
          }
          buttons {
            title
            url
          }
        }
        secImages {
          alternativeText
          localFile {
            childImageSharp {
              gatsbyImageData
            }
            publicURL
          }
          url
        }
        buttons {
          title
          url
        }
        description: childStrapiComponentSectionsSectionDescriptionTextnode {
          id
          description
        }
      }
      buttons {
        url
        title
      }
      seo {
        metaTitle
        metaDescription
        schemas {
          visibilityIn
          structuredData
        }
      }
    }
  }
`

export default mobileApp
