// extracted by mini-css-extract-plugin
export var cyberWorkPortFolio = "WorkPortfolio-module--cyberWorkPortFolio--ffbeb";
export var h3Alt = "WorkPortfolio-module--h3Alt--3441b";
export var healthCarePortHeading = "WorkPortfolio-module--healthCarePortHeading--24986";
export var iconContainer = "WorkPortfolio-module--iconContainer--137e5";
export var iconContainerLeft = "WorkPortfolio-module--iconContainerLeft--543e7";
export var imgContainer = "WorkPortfolio-module--imgContainer--84804";
export var portContent = "WorkPortfolio-module--portContent--38182";
export var portfolioArrowIcon = "WorkPortfolio-module--portfolioArrowIcon--494f3";
export var portfolioArrowIconCover = "WorkPortfolio-module--portfolioArrowIconCover--52c92";
export var portfolioArrowRightIconCover = "WorkPortfolio-module--portfolioArrowRightIconCover--2dde0";
export var subTitle = "WorkPortfolio-module--subTitle--ffa43";
export var workPortfolio = "WorkPortfolio-module--workPortfolio--e17ff";