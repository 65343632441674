import { graphql, Link, useStaticQuery } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import React from "react"
import Button from "react-bootstrap/Button"
import Col from "react-bootstrap/Col"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Slider from "react-slick"
import "slick-carousel/slick/slick-theme.scss"
import "slick-carousel/slick/slick.scss"
import * as styles from "./WorkPortfolio.module.scss"
import "./sliderDotClass.scss"

function Arrow(props) {
  const { onClick } = props
  return (
    <div
      className={`${styles.iconContainer} ${styles.portfolioArrowRightIconCover}`}
      onClick={onClick}
    >
      <img
        src={
          "https://invozone-backend.s3.us-east-1.amazonaws.com/arrow_right_444cea6456.svg"
        }
        alt="right arrow"
        onClick={onClick}
        className={styles.portfolioArrowIcon}
        decoding="async"
        loading="lazy"
        height={25}
        width={25}
      />
    </div>
  )
}
function PrevArrow(props) {
  const { onClick } = props
  return (
    <div
      className={`${styles.iconContainerLeft} ${styles.portfolioArrowIconCover}`}
      onClick={onClick}
    >
      <img
        src={
          "https://invozone-backend.s3.us-east-1.amazonaws.com/arrow_left_ff8e28ead0.svg"
        }
        alt="left arrow"
        className={styles.portfolioArrowIcon}
        decoding="async"
        loading="lazy"
        height={25}
        width={25}
      />
    </div>
  )
}

const WorkPortfolio = ({
  bgColor = "#f2f2f2",
  pt,
  strapiData,
  portfolioHead,
  portfolioDesc,
  cyberWorkPortfolio,
  healthCarePortfolio,
  isScroll,
}) => {
  const data = useStaticQuery(graphql`
    query WorkPortfolioItemsQuery_1 {
      strapiPage(slug: { eq: "work-portfolio" }) {
        slug
        sections {
          title
          subTitle
          cards {
            title
            slug: title2
            subTitle
            image1 {
              localFile {
                childImageSharp {
                  gatsbyImageData
                }
                publicURL
              }
            }
          }
        }
      }
    }
  `)

  const { sections: items } = data.strapiPage

  const settings = {
    dots: true,
    autoplay: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    adaptiveHeight: true,
    infinite: true,
    arrows: true,
    prevArrow: <PrevArrow />,
    nextArrow: <Arrow />,
    dotsClass: "button__bar",
    lazyLoad: "ondemand",
    responsive: [
      {
        breakpoint: 768,
        settings: {
          arrows: false,
          dots: true,
        },
      },
    ],
  }

  return (
    <div
      className={`${styles.workPortfolio} WorkPortfolio`}
      style={{ background: `${bgColor}`, paddingTop: pt }}
    >
      <Container>
        <div className={styles.portContent}>
          <h2
            className={` main-heading-36  ${
              healthCarePortfolio ? styles.healthCarePortHeading : ""
            }`}
            dangerouslySetInnerHTML={{
              __html: strapiData?.title || portfolioHead || "Work Portfolio",
            }}
          />
          <p
            className={cyberWorkPortfolio ? styles.cyberWorkPortFolio : ""}
            dangerouslySetInnerHTML={{
              __html:
                strapiData?.subTitle ||
                portfolioDesc ||
                "Transform your vision into reality and drive exemplary results through InvoZone's dynamic engagement models.",
            }}
          />
        </div>
        <Slider {...settings}>
          {items[0]?.cards?.map((item, i) => {
            return (
              <Row key={i} className="d-flex">
                <Col md={6} className={`${styles.imgContainer} mt-5 mt-md-0`}>
                  <GatsbyImage
                    placeholder="blurred"
                    decoding="async"
                    loading="lazy"
                    image={getImage(item?.image1[0]?.localFile)}
                    alt={item?.title}
                    width={"100%"}
                    height={"100%"}
                    // style={{ maxWidth: '100%', height: 'auto' }} // Adjust image size
                  />
                </Col>
                <Col md={6} className="pt-md-5  mt-md-5">
                  <div className="h3Alt pt-md-3 text-20 simple-font-weight">
                    {item?.title}
                  </div>
                  <div className={`${styles.subTitle} my-md-4 pb-3`}>
                    {item?.subTitle}
                  </div>
                  {isScroll && (
                    <Link to={`/portfolio/${item?.slug}/`}>
                      <Button className="btn_black_border mb-5 mb-lg-0">
                        View Portfolio
                      </Button>
                    </Link>
                  )}
                </Col>
              </Row>
            )
          })}
        </Slider>
      </Container>
    </div>
  )
}

export default WorkPortfolio
